import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;
    }
    .colorFuente{
        color: #424242;
    }
    .App{  
        height: 100vh;
        display: flex; 
        flex-direction: column; 
    }
    body{
        background: #EEEEEE;
        overflow-x: hidden;
    }

    html{
        @media (max-width: 1700px){
            font-size: 75%;
        }
    }
`;

export default GlobalStyle;
