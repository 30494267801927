import React from "react";
import styled from "styled-components";
import { Button, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";

const NewCard = ({ title, image, link, subtitulo, autor }) => {
  const windowSizeData = useSelector((state) => state.windowSize);
  var CHARACTER_LIMIT =
    windowSizeData.payload && windowSizeData.payload.width > 750 ? 20 : 16;
  // Titulo
  var titleText = title;
  var sePasaTit = false;
  if (titleText.length > CHARACTER_LIMIT) {
    titleText = titleText.substring(0, CHARACTER_LIMIT);
    titleText += " ...";
    sePasaTit = true;
  }
  // Subtitulo
  var subText = subtitulo;
  var sePasaSub = false;
  if (subText.length > CHARACTER_LIMIT) {
    subText = subText.substring(0, CHARACTER_LIMIT);
    subText += " ...";
    sePasaSub = true;
  }
  // Autor
  var autorText = autor;
  var sePasaAut = false;
  if (autorText.length > CHARACTER_LIMIT) {
    autorText = autorText.substring(0, CHARACTER_LIMIT);
    autorText += " ...";
    sePasaAut = true;
  }
  return (
    <StyDiv>
      <StyImgCont>
        <img src={image} alt={title} />
      </StyImgCont>
      {sePasaTit ? (
        <Tooltip title={title} placement="top" arrow>
          <h2>{titleText}</h2>
        </Tooltip>
      ) : (
        <h2>{titleText}</h2>
      )}
      {sePasaSub ? (
        <Tooltip title={subtitulo} placement="top" arrow>
          <h3>{subtitulo.length > 4 ? subText : ""}</h3>
        </Tooltip>
      ) : (
        <h3>{subtitulo.length > 4 ? subtitulo : <br />}</h3>
      )}
      {sePasaAut ? (
        <Tooltip title={autor} placement="left" arrow>
          <h4>{autorText}</h4>
        </Tooltip>
      ) : (
        <h4>{autorText}</h4>
      )}

      <Button
        onClick={() => {
          window.open(link);
        }}
        variant="contained"
        size="small"
        color="primary"
        className="boton"
      >
        Descargar
      </Button>
    </StyDiv>
  );
};

const StyDiv = styled.div`
  height: 100%;
  width: 12vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 4px;
  filter: drop-shadow(5px 6px 3px rgba(0, 0, 0, 0.1));
  overflow-x: hidden;
  h2 {
    font-size: 1vw;
    text-align: center;
    margin-top: 0.5rem;
    cursor: pointer;
    user-select: none;
  }
  h3 {
    font-size: 0.8vw;
    cursor: pointer;
    user-select: none;
  }
  h4 {
    font-size: 0.8vw;
    font-weight: 400;
    margin-bottom: 0.5rem;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
  .boton {
    margin-bottom: 0.5rem;
  }
  @media (max-width: 750px) {
    width: 30vw;
    height: 50vw;
    align-self: center;
    h2 {
      font-size: 3vw;
    }
    h3 {
      font-size: 2vw;
    }
    h4 {
      font-size: 2vw;
    }
  }
`;

const StyImgCont = styled.div`
  height: 12vw;
  width: 12vw;
  overflow: hidden;
  background-color: #808080;
  border-radius: 4px 4px 0 0;
  img {
    width: 100%;
  }
  @media (max-width: 750px) {
    width: 30vw;
    height: 30vw;
  }
`;

export default NewCard;
