import React from "react";
import styles from "./style.module.css";
import OgitechLogo from "../../assets/ogi.svg";
import LockwardLogo from "../../assets/lockward.jpeg";
import MinisterioLogo from "../../assets/ministerio.png";
const Footer = () => {
  return (
    <div className={styles.footer_container}>
      <div className={styles.texto_footer}>
        <p>Biblioteca digital Ángel Lockward</p>
      </div>
      <div className={styles.imagenes_footer}>
        <img src={OgitechLogo} alt="" />
        <img src={LockwardLogo} alt="" />
      </div>
    </div>
  );
};

export default Footer;
