import React from "react";
// Material UI
import { Grid } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// Framer Motion
import { motion } from "framer-motion";
import { CardAnim, h2Anim } from "../animation";
// Redux
import { useSelector } from "react-redux";
// Router
import { Link } from "react-router-dom";
// Components
import MediaCard from "./NewCard";
// Styled comp
import { StyDiv } from "../style";
// Utility
import { MayusMaker } from "../Utility";
// uuid
import { v4 as uuidv4 } from "uuid";

export default function CenteredGrid({ title }) {
  const booksData = useSelector((state) => state.libros);
  const windowSizeData = useSelector((state) => state.windowSize);
  let filterData;
  if (booksData.payload !== undefined) {
    filterData = booksData.payload.filter((book) =>
      book.genero.includes(title)
    );
  }
  return (
    <StyDiv>
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={`/category/${title}`}
        style={{ textDecoration: "none" }}
      >
        <motion.h2
          variants={h2Anim}
          initial="hidden"
          animate="show"
          className="titulo colorFuente"
        >
          {MayusMaker(title)} <ChevronRightIcon />
        </motion.h2>
      </Link>
      <Grid
        container
        justify="center"
        direction="row"
        alignItems="flex-start"
        spacing={1}
      >
        {filterData &&
          filterData.slice(0, 4).map((book) => {
            return (
              <Grid
                item
                xs={windowSizeData.payload.width > 750 ? true : 6}
                key={uuidv4()}
              >
                <motion.div
                  variants={CardAnim}
                  initial="hidden"
                  animate="show"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MediaCard
                    title={book.titulo}
                    image={book.portada}
                    link={book.link1}
                    subtitulo={book.subtitulo}
                    autor={book.autor}
                  />
                </motion.div>
              </Grid>
            );
          })}
      </Grid>
    </StyDiv>
  );
}
